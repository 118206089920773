import { DeserializableModel } from './deserializable.model';
import { Partner } from './partner.model';

export class PartnerAddress extends DeserializableModel {
  code!: string;
  name1!: string;
  name2!: string;
  addressLine!: string;
  zipCode!: string;
  town!: string;
  country!: string;
  pobox!: string | null;
  partner!: Partner;
  email!: string;
  deserialize(input: PartnerAddress, partner: Partner): this {
    Object.assign(this, input);
    this.partner = partner;
    return this;
  }
}
