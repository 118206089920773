import { DeserializableModel } from '@shared/models/deserializable.model';
import { PartnerServiceItem } from '@shared/models/partner-service-item.model';
import { PartnerAddress } from './partner-address.model';
import { DiscountUnits } from '../enums/discount-units.enum';

export class Partner extends DeserializableModel {
  companyName!: string;
  partnerCode!: string;
  contact_details!: string;
  partnerServiceItems: PartnerServiceItem[] = [];
  addressList: PartnerAddress[] = [];
  loyaltyDiscountActive!: boolean;
  loyaltyDiscountWeeks!: number;
  loyaltyDiscountValueData!: number;
  loyaltyDiscountValueUnit!: DiscountUnits;
  multiEyewearActive!: boolean;
  multiEyewearWeeks!: number;
  multiEyewearValueData!: number;
  multiEyewearValueUnit!: DiscountUnits;
  cleaningClothXLActive!: boolean;
  logo?: string;
  deserialize(input: Partner): this {
    Object.assign(this, input);
    this.partnerServiceItems = input.partnerServiceItems?.map(
      (partnerServiceItem: PartnerServiceItem) =>
        new PartnerServiceItem().deserialize(partnerServiceItem, this),
    );
    this.addressList = input.addressList?.map(
      (partnerAddress: PartnerAddress) =>
        new PartnerAddress().deserialize(partnerAddress, this),
    );
    return this;
  }
}
