import { DeserializableModel } from '@shared/models/deserializable.model';
import { User } from './user.model';
import { Partner } from './partner.model';
import { MailLog } from './mail-log.model';

export class Purchase extends DeserializableModel {
  partnerCode!: string;
  orderNr!: string;
  purchasedAt!: Date;
  user: User | null = null;
  partner: Partner | null = null;
  mailLogs: MailLog[] = []; // One purchase can have multiple mail logs

  deserialize(input: Purchase): this {
    Object.assign(this, input);
    this.partner = input.partner
      ? new Partner().deserialize(input.partner)
      : null;
    this.user = input.user ? new User().deserialize(input.user) : null;
    this.mailLogs = input.mailLogs
      ? input.mailLogs.map((log: MailLog) => new MailLog().deserialize(log))
      : [];
    return this;
  }
}
